import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

$(document).ready(() => {
    var elements = $('.swiper-custom');
    $(elements).each(function( index ) {
        initSwiperCustom($(this));
    });
});

function initSwiperCustom(elem)
{
    var id = elem.attr('id');
    if (id == '') {
        console.log('initSwiper : aucun id de défini');
        return;
    }
    //Get datas
    var data_speed = parseInt(elem.data('speed'));
    var data_autoplay = elem.data('autoplay');
    var data_pause_hover = elem.data('pause-hover');
    var data_autoplay_speed = parseInt(elem.data('autoplay-speed'));
    var data_repeat = elem.data('repeat');
    var data_arrows_mobile = elem.data('arrows-mobile');
    var data_arrows_desktop = elem.data('arrows-desktop');
    var data_dots_mobile = elem.data('dots-mobile');
    var data_dots_desktop = elem.data('dots-desktop');
    var data_slides_per_view_mobile = elem.data('slides-mobile');
    var data_slides_per_view_tablet = elem.data('slides-tablet');
    var data_slides_per_view_desktop = elem.data('slides-desktop');


    //Apply datas or default config
    var speed = setIntOrDefault(data_speed, 300);
    var autoplay = setBoolOrDefault(data_autoplay, false);
    var pause_hover = setBoolOrDefault(data_pause_hover, false);
    var autoplay_speed = setBoolOrDefault(data_autoplay_speed, 3000);
    var repeat = setBoolOrDefault(data_repeat, true);
    var arrows_mobile = setBoolOrDefault(data_arrows_mobile, true);
    var arrows_desktop = setBoolOrDefault(data_arrows_desktop, true);
    var dots_mobile = setBoolOrDefault(data_dots_mobile, true);
    var dots_desktop = setBoolOrDefault(data_dots_desktop, true);
    var slides_per_view_mobile = setIntOrDefault(data_slides_per_view_mobile, 2);
    var slides_per_view_tablet = setIntOrDefault(data_slides_per_view_tablet, 4);
    var slides_per_view_desktop = setIntOrDefault(data_slides_per_view_desktop, 6);


    //Set Arrows
    setArrowsSwiperCustom(elem, arrows_mobile, arrows_desktop);

    //Set Pagination
    setPaginationSwiperCustom(elem, dots_mobile, dots_desktop);


    const config_swiper = {
        slidesPerView: slides_per_view_mobile,
        spaceBetween: 0,
        loop: repeat,
        speed: speed,
        fadeEffect: {
            crossFade: true
        },
        breakpoints: {
            768: {
                slidesPerView: slides_per_view_tablet
            },
            1200: {
                slidesPerView: slides_per_view_desktop
            },
        }
    };

    const modules = [];

    //Pagination
    if (dots_desktop || dots_mobile) {
        modules.push(Pagination);
        config_swiper.pagination = {
            el: "."+id+"-swiper-pagination",
            clickable: true
        };
    }

    //Navigation
    if (arrows_desktop || arrows_mobile) {
        modules.push(Navigation);
        config_swiper.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        };
    }

    //AutoPlay
    if (autoplay) {
        modules.push(Autoplay);
        config_swiper.autoplay = {
            delay: autoplay_speed,
            disableOnInteraction: false,
            pauseOnMouseEnter: pause_hover
        };
    }

    //Modules
    config_swiper.modules = modules;

    console.log(config_swiper);

    //Init Swiper
    var swiper = new Swiper("#"+id, config_swiper);
}

/**
 * Permet d'ajouter les balises liées aux flèches de navigation
 * @param elem
 * @param arrows_mobile
 * @param arrows_desktop
 */
function setArrowsSwiperCustom(elem, arrows_mobile, arrows_desktop)
{

    var has_arrow_next = elem.find('.swiper-button-next').length;
    var has_arrow_prev = elem.find('.swiper-button-prev').length;

    if (!has_arrow_prev && (arrows_mobile || arrows_desktop)) {
        //Insert arrow prev
        var arrow_prev_classes = (arrows_mobile ? 'd-flex' : 'd-none')+' '+(arrows_desktop ? 'd-md-flex' : 'd-md-none');
        var arrow_prev = '<div class="swiper-button-prev '+arrow_prev_classes+'"></div>';
        elem.append(arrow_prev);
    }

    if (!has_arrow_next && (arrows_mobile || arrows_desktop)) {
        //Insert arrow next
        var arrow_next_classes = (arrows_mobile ? 'd-flex' : 'd-none')+' '+(arrows_desktop ? 'd-md-flex' : 'd-md-none');
        var arrow_next = '<div class="swiper-button-next '+arrow_next_classes+'"></div>';
        elem.append(arrow_next);
    }
}

/**
 * Permet d'ajouter les balises liées aux points de pagination
 * @param elem
 * @param dots_mobile
 * @param dots_desktop
 */
function setPaginationSwiperCustom(elem, dots_mobile, dots_desktop)
{
    var has_pagination = elem.find('.swiper-pagination').length;
    if (!has_pagination && (dots_mobile || dots_desktop)) {
        var id = elem.attr('id');
        var pagination_classes = (dots_mobile ? 'd-block' : 'd-none')+' '+(dots_desktop ? 'd-md-block' : 'd-md-none');
        var pagination = '<div class=".'+id+'swiper-pagination swiper-pagination '+pagination_classes+'"></div>';
        elem.append(pagination);
    }
}

/**
 * Permet de retourner une valeur (int) ou une valeur par défaut
 * @param value
 * @param default_value
 * @returns {*}
 */
function setIntOrDefault(value, default_value)
{
    if (isNaN(value) || value === 0) {
        return default_value;
    } else {
        return value;
    }
}

/**
 * Permet de retourner une valeur (bool) ou une valeur par défaut
 * @param value
 * @param default_value
 * @returns {*}
 */
function setBoolOrDefault(value, default_value)
{
    var allowed_values = [true, false];
    if (allowed_values.includes(value)) {
        return value;
    } else {
        return default_value;
    }
}